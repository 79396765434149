import { Injectable } from '@angular/core';
import { AppState } from 'app/shared/common/utils/AppState';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { IAgGridRowStatus } from './ag-grid-without-cache.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridWithoutCacheService {
  constructor(
    public httpHelper: HttpHelper,
    public appState: AppState
  ) { }

  pivotPaging(request: any, apiPivotPaging: string): Promise<any> {
    const keySearch = Object.keys(request.filterModel)[0];

    if (apiPivotPaging.includes("object/pivotPaging") && request.filterModel[keySearch]?.filter?.length === 0
      && window.location.hash.includes("manage/search-contract")) return Promise.resolve({
        data: [],
        lastRow: null,
        secondaryColumnFields: [],
        isNotNotified: true,
      });

    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiPivotPaging, params);
  }

  pivotCount(request: any, apiCount: string): Promise<any> {
    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiCount, params);
  }

  updateRowStatus(data: IAgGridRowStatus, apiUpDateRecordStatus: string): Promise<any> {
    // console.log('apiUpDateRecordStatus', apiUpDateRecordStatus)
    return this.httpHelper.methodPostService(apiUpDateRecordStatus, data);
  }

  getUserAttribute(pageAttribute: string): Promise<any> {
    return this.appState.getAttributeValue(pageAttribute);
  }

  setUserAttribute(pageAttribute: string, atributeValue: string): Promise<any> {
    return this.appState.setAttributeValue(pageAttribute, atributeValue);
  }
}
