import { NotificationConstant } from './notification.constant';
import { MarkAsRead } from './notification.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { PageNotificationService } from 'app/modules/notifications/notification/page-notification.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupDetailComponent } from './popup-detail/popup-detail.component';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _pageNotificationService: PageNotificationService,
        private _router: Router,
        public dialog: MatDialog

    ) {
    }
    ngOnChanges(changes: SimpleChanges): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngAfterViewInit() {
        this._notificationsService._isTriggerAPIPagingListNotification
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isTrigger => {
                if (isTrigger) {
                    this._calculateUnreadCount();
                    this._notificationsService.triggerAPIPagingOnHome(false);
                }
            });
        this._notificationsService._triggerCountNotificationUnread
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isTrigger => {
                if (isTrigger) {
                    this._notificationsService.unreadCount = this._notificationsService.unreadCount + 1;
                    this._notificationsService.isReloadData = true;
                    this._changeDetectorRef.markForCheck();
                    // handle show mess o day 
                    // this._utilComp.showToastClick('New','Message',()=>{
                    //     this.openDialogNotificationDetail(isTrigger);
                    // });
                }
            });
    }
    /**
     * On init
     */
    async ngOnInit(): Promise<void> {

        this._calculateUnreadCount();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    async openPanel(): Promise<void> {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        if (this._notificationsService.isReloadData) {
            const data = await this._notificationsService.getPagingList('', NotificationConstant.LIMIT);
            if (data.length === 0) {
                this._notificationsService.notifications = [];
            } else {
                for (const item of data.data) {
                    item.object = JSON.parse(item.object)
                }
                this._notificationsService.notifications = data.data;
            }
            this._notificationsService.isReloadData = false;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    async toggleRead(data: any) {
        try {
            const request = new MarkAsRead();
            request.aggId = data.aggId;
            request.unread = data.unread === NotificationConstant.UNREAD ? NotificationConstant.READ : NotificationConstant.UNREAD;
            const resonse = await this._notificationsService.markAsReadService(request);
            // trigger list notification in PAge Notifiication
            this._pageNotificationService.triggerAPIPagingPageNotification(true);
            this._calculateUnreadCount();
        }
        catch (err) {
            // tslint:disable-next-line: no-console
            console.log('err', err)
        }
    }

    async markAllAsRead() {
        try {
            const resonse = await this._notificationsService.markAsAllReadService();
            this._pageNotificationService.triggerAPIPagingPageNotification(true);
            this._calculateUnreadCount();
        }
        catch (err) {
            // tslint:disable-next-line: no-console
            console.log('err', err)
        }
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    async _calculateUnreadCount(): Promise<void> {
        try {
            const countUnread = await this._notificationsService.getCountUnreadNotification();
            this._notificationsService.unreadCount = countUnread;
            const data = await this._notificationsService.getPagingList('', NotificationConstant.LIMIT);
            if (data.length === 0) {
                this._notificationsService.notifications = [];
            } else {
                for (const item of data.data) {
                    item.object = JSON.parse(item.object)
                }
                this._notificationsService.notifications = data.data;
            }
            this._changeDetectorRef.markForCheck();
        } catch (err) {
            // tslint:disable-next-line: no-console
            console.log('err', err)
        }
    }

    movetoPageNotifications() {
        this._pageNotificationService.setAggId(null);
        this._router.navigate(['notification']);
    }

    async openDialogNotificationDetail(data: any) {
        if (!data) return;
        const detail = new MarkAsRead();
        detail.aggId = data.aggId;
        detail.unread = NotificationConstant.UNREAD;
        this.toggleRead(detail);
        const detailNotification = await this._pageNotificationService.getDetailNotificationService(data.aggId)
        this.dialog.open(PopupDetailComponent, {
            data: {
                content: detailNotification.content,
                detailNotification
            }
        });
    }
}
