import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
// import * as Sentry from '@sentry/angular';
// import { BrowserTracing } from '@sentry/tracing';

if ( environment.production )
{
    enableProdMode();
}

// Sentry.init({
//     dsn: environment.sentry_dns,
//     environment: environment.name,
//     release: environment.version,
//     ignoreErrors: [
//         "Uncaught (in promise): Error: NG04002: Cannot match any routes. URL Segment",
//         "Uncaught (in promise): Error: NG04002: Cannot match any routes. URL Segment"
//     ],
//     integrations: [
//         new BrowserTracing({
//             tracingOrigins: environment.sentry_tracing,
//             routingInstrumentation: Sentry.routingInstrumentation,
//           }),
//     ],
//     tracesSampleRate: 1.0,
// });

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
