import { Injectable } from "@angular/core";
// import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { NotificationsService } from "app/layout/common/notifications/notifications.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { UtilCommon } from "../UtilCommon";
import { UtilComponent } from "../UtilComponent";
// import * as moment from 'moment';
import { APP_CONFIG } from "app.config";
import { AppStateConstant } from "../../constants/AppState.constant";

@Injectable({
    providedIn: 'root'
})
export class FireStoreService {
    subcriptionNew: Subscription;
    subcriptionApp: Subscription;
    isFirstEnter: boolean = false;
    preData;
    messageUpdate: String = '';
    constructor(
        // private afs: AngularFirestore,
        private utilComp: UtilComponent,
        private _translateService: TranslocoService,
        private router: Router,
        private notificationsService: NotificationsService) {
    }

    initFireStoreEvent(userName: string) {
        try {
            if (window['isMobile']) {
                return;
            }
            // this.subcriptionNew = this.afs.collection('envelopes').doc('envelopes').collection(userName).stateChanges(['added', 'modified']).pipe(
            //     map(actions => actions.map(a => {
            //         const data = a.payload.doc.data();
            //         const id = a.payload.doc.id;
            //         return { id, ...data };
            //     }))
            // ).subscribe((d) => {
            //     if (window['isMobile']) {
            //         return;
            //     }
            //     // console.log(this.isFirstEnter, d);
            //     if (UtilCommon.isArray(d) && this.isFirstEnter) {
            //         if (d.length > 1) {
            //             return;
            //         }
            //         const data: any = d[0];
            //         const appState = window[AppStateConstant.appState];
            //         if (data && appState) {
            //             if (!this.preData) {
            //                 this.preData = data;
            //             } else {
            //                 // && this.preData?.status === data?.status &&
            //                 // this.preData?.taskId === data?.taskId
            //                 if (this.preData.aggId === data.aggId) {
            //                     const date1 = moment(this.preData?.updatedDateTime?.toDate());
            //                     const date2 = moment(data?.updatedDateTime?.toDate());
            //                     const inSeconds = date2.diff(date1, 'seconds');
            //                     if (inSeconds <= 2) {
            //                         this.notificationsService._triggerCountNotificationUnread.next(data);
            //                         return;
            //                     }
            //                 }
            //             }

            //             this.preData = data;

            //             const tenantCode = appState?.moreInfo?.tenantCode;
            //             if (UtilCommon.isNotNull(tenantCode)) {
            //                 if (tenantCode == data.tenantCode) {
            //                     this.notificationsService._triggerCountNotificationUnread.next(data);
                                
            //                     if (location.href.indexOf(data.aggId) > -1 ||
            //                         location.href.indexOf('apps/envelope') > -1 ||
            //                         location.href.indexOf('sign-document/sign') > -1 ||
            //                         location.href.indexOf('sign-document?accessKey') > -1) {
            //                         if (location.href.indexOf('sign-document/sign') > -1 || location.href.indexOf('sign-document?accessKey') > -1) {
            //                             this.postMessage();
            //                         }
            //                         return;
            //                     }
            //                     if (data.status) {
            //                         data.statusTemp = 'ENVELOP.STATUS_' + data.status.toUpperCase();
            //                     }
            //                     const mess = this._translateService.translate('MENU.ENVELOPE') + ' <b>' + data.envelopeCode + '</b> ' + this._translateService.translate('MESSAGE_GENERAL.HAVE_CHANGE_STATUS') + '  <b>' + this._translateService.translate(data.statusTemp) + ' </b><br>' + this._translateService.translate('MESSAGE_GENERAL.CLICK_TO_VIEW_DETAIL');
            //                     this.utilComp.showToastClick(mess, '', async () => {
            //                         let url = '';
            //                         if (data.taskId) {
            //                             const params = data.aggId + '#' + data.taskId;
            //                             url = '/apps/envelope/inbox/1/' + encodeURIComponent(params);
            //                         } else {
            //                             url = '/apps/envelope/inbox/1/' + encodeURIComponent(data.aggId);
            //                         }
            //                         return this.router.navigateByUrl(url);
            //                     });
            //                 }
            //             }
            //         }
            //     }
            //     this.isFirstEnter = true;
            // });

            // this.subcriptionApp = this.afs.collection('app').doc('config').valueChanges('versionWeb').pipe(
            //     map(action => action)
            // ).subscribe((dataConfig: any) => {
            //     if (window['isMobile']) {
            //         return;
            //     }
            //     if (dataConfig) {
            //         if (!dataConfig?.versionWeb) {
            //             return;
            //         }
            //         // Trưởng hợp user đã cancel ta sẽ không hỏi nữa
            //         if (localStorage.getItem('versionWeb') === dataConfig?.versionWeb) {
            //             return;
            //         }
            //         if (dataConfig?.versionWeb !== APP_CONFIG.VERSION) {
            //             localStorage.setItem('versionWeb', dataConfig?.versionWeb);
            //             this.utilComp.openConfirmationDialogCancelCallback('Ứng dụng đã có phiên bản mới. Bạn vui lòng nhấn Đồng ý để tải lại trang. Hoặc nhấn Hủy để tiếp tục thao tác.', () => {
            //                 window.location.reload();
            //             }, () => { }, dataConfig?.messageWeb, this._translateService.translate('CONTROL.OK'), this._translateService.translate('CONTROL.CANCEL'));
            //         }
            //     }
            // });
        } catch (e) {
            console.log(e);
        }
    }

    postMessage() {
        const iframe = this.getIframe();
        if (iframe) {
            const dataPostMessage = {
                'action': 'RELOAD',
                'username': localStorage.getItem(AppStateConstant.userName)
            };
            iframe.contentWindow.postMessage(JSON.stringify(dataPostMessage), '*');
        }
    }

    getIframe(): any {
        const iframePDF: any = document.getElementById('iframePDF');
        if (iframePDF) {
            return iframePDF;
        }
        return null;
    }

    logout() {
        this.subcriptionNew.unsubscribe();
        this.isFirstEnter = false;
        this.preData = null;
    }
}