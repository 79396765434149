import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';

@Injectable({
    providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader
{
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang
     */
    getTranslation(lang: string): Observable<Translation>
    {
        let link = `./assets/i18n/${lang}.json`;
        if(!UtilCommon.isCRMApp()){
            link = `./assets/i18n/hunt/${lang}.json`;
        }
        return this._httpClient.get<Translation>(link);
    }
}
