import { HttpErrorResponse } from '@angular/common/http';
import { AppStateConstant } from '../../constants/AppState.constant';

export const toHttpErrorLog = (input: HttpErrorResponse, event: string = "HttpErrorResponse") => {
    return {
        screen: window.location.href,
        event: event,
        message: input.message
    };
}

export const toProgramlog = (input: Error, event: string = '') => {
    const app = localStorage.getItem(AppStateConstant.appState);
    let userName = "";
    if (app) {
        const parseData = JSON.parse(app);
        userName = parseData?.moreInfo.userName;
    }
    return {
        screen: window.location.href,
        event: event || '',
        message: input.message,
        stack: (input.stack as string)?.substring(0, 180) + "...",
        userName
    };
}