import { AppState } from 'app/shared/common/utils/AppState';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';
import { UtilCommon } from './UtilCommon';
import { STATUS_IN_DATABASE, STATUS_IN_DB, STATUS_IN_VIEW } from '../constants/Status.constant';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { MOMENT_DATE_FORMAT } from '../config/MomentDateFormat.config';
@Injectable({ providedIn: 'root' })
export class UtilComponent {

    constructor(private snackBar: MatSnackBar,
        private _translateService: TranslocoService,
        private _toastrService: ToastrService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _appState: AppState
    ) {

    }

    showToastError(message, title = '', toastClass: string = 'ngx-toastr', timeout: number = 4000) {
        this._toastrService.error(message, title, {
            timeOut: timeout,
            enableHtml: true,
            toastClass: toastClass,
            extendedTimeOut: timeout,

        });
    }

    showToastErrorAutoDismiss(message, title = '', toastClass: string = 'ngx-toastr', timeout: number = 4000) {
        this._toastrService.error(message, title, {
            timeOut: timeout,
            enableHtml: true,
            toastClass: toastClass,
            extendedTimeOut: timeout,
        });
    }

    showToastSuccess(message, title = '', toastClass: string = 'ngx-toastr', timeout: number = 4000) {
        this._toastrService.success(message, title, {
            timeOut: timeout,
            enableHtml: true,
            toastClass: toastClass,
            extendedTimeOut: timeout,

        });
    }

    showToastWarning(message, title = '', toastClass: string = 'ngx-toastr', timeout: number = 4000) {
        this._toastrService.warning(message, title, {
            timeOut: timeout,
            enableHtml: true,
            toastClass: toastClass,
            extendedTimeOut: timeout,

        });
    }

    showToastClick(message, title = '', callback, type: string = 'success', toastClass: string = 'ngx-toastr', timeout: number = 5000) {
        if (type === 'success') {
            this._toastrService.success(message, title, {
                timeOut: timeout,
                enableHtml: true,
                toastClass: toastClass,
                extendedTimeOut: timeout,
            })
                .onTap
                .pipe(take(1))
                .subscribe(() => {
                    if (callback) {
                        callback.call();
                    }
                });
        } else {
            this._toastrService.error(message, title, {
                timeOut: timeout,
                enableHtml: true,
                toastClass: toastClass,
                extendedTimeOut: timeout,
            }).onTap
                .pipe(take(1))
                .subscribe(() => {
                    if (callback) {
                        callback.call();
                    }
                });
        }
    }

    generateProgressButton(text: string, disabled: boolean = false, active: boolean = false, spinnerSize: number = 18, raised: boolean = true,
        buttonColor: string = 'accent',
        spinnerColor: string = 'mat-white', fullWidth: boolean = true,
        mode: string = 'indeterminate'): any {
        const spinnerButtonOptions: any = {
            active: active,
            text: text,
            spinnerSize: spinnerSize,
            raised: raised,
            buttonColor: buttonColor,
            spinnerColor: spinnerColor,
            fullWidth: fullWidth,
            disabled: disabled,
            mode: mode
        };

        return spinnerButtonOptions;
    }


    generateDialogAlert(message: string, title: string = '', label: string = this._translateService.translate('CONTROL.CLOSE')): void {
        const configForm = {
            title: title,
            message: message,
            actions: {
                cancel: {
                    show: true,
                    label: label
                },
                confirm: {
                    show: false
                }
            },
            dismissible: true
        };
        const dialogRef = this._fuseConfirmationService.open(configForm);

        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {

        });
    }

    generateDialogAlertWithCallBack(callBack: any, message: string, title: string = '', closeButton: string = 'Close',
        disableClose: boolean = false,
        viewContainerRef: any = null, width: string = '400px'): void {
        // this.dialogService.openAlert({
        //     message: message,
        //     disableClose: disableClose, // defaults to false
        //     viewContainerRef: viewContainerRef, // OPTIONAL
        //     title: title, // OPTIONAL, hides if not provided
        //     closeButton: closeButton, // OPTIONAL, defaults to 'CLOSE'
        //     width: width, // OPTIONAL, defaults to 400px
        // }).afterClosed().subscribe((accept: boolean) => {
        //     callBack.call();
        // });
    }

    generateDialogConfirm(callbackAccept: any, callbackCancel: any, message: string, title: string = 'Xác nhận', disableClose: boolean = false,
        cancelButton: string = 'Hủy', acceptButton: string = 'Xác nhận', viewContainerRef: any = null, width: string = '500px'): void {
        // this.dialogService.openConfirm({
        //     message: message,
        //     disableClose: disableClose, // defaults to false
        //     viewContainerRef: viewContainerRef, // OPTIONAL
        //     title: title, // OPTIONAL, hides if not provided
        //     cancelButton: cancelButton, // OPTIONAL, defaults to 'CLOSE'
        //     acceptButton: acceptButton,  // OPTIONAL, defaults to 'ACCEPT'
        //     width: width, // OPTIONAL, defaults to 400px
        // }).afterClosed().subscribe((accept: boolean) => {
        //     if (accept) {
        //         callbackAccept.call();
        //     } else {
        //         callbackCancel.call();
        //     }
        // });
    }

    openSnackBar(message: string, type: string = 'complete', action: string = '', duration: number = 5000) {
        // const config: any = new MatSnackBarConfig();
        // if (type === 'complete') {
        //     config.panelClass = ['complete-snackbar'];
        // } else if (type === 'error') {
        //     config.panelClass = ['error-snackbar'];
        // }
        // // else if (type === 'error') {
        // //     config.panelClass = ['error-snackbar'];
        // // }
        // config.duration = duration;
        // // config.panelClass = ['custom-class'];
        // return this.snackBar.open(message, action, config);
        if (type === 'complete') {
            return this.showToastSuccess(message, '');
        }
        if (type === 'warning') {
            return this.showToastWarning(message, '');
        }
        return this.showToastError(message, '');
    }

    openSnackBarWithAction(message: string, type: string = '', action: string = '', duration: number = 5000) {
        const config: any = new MatSnackBarConfig();
        if (type === 'complete') {
            config.panelClass = ['complete-snackbar-with-action'];
        } else if (type === 'error') {
            config.panelClass = ['error-snackbar'];
        }
        config.duration = duration;
        // config.panelClass = ['custom-class'];
        return this.snackBar.open(message, action, config);

    }

    openSnackBarError(message: string, action: string = '', duration: number = 5000) {
        // const config: any = new MatSnackBarConfig();

        // config.panelClass = ['error-snackbar'];
        // // else if (type === 'error') {
        // //     config.panelClass = ['error-snackbar'];
        // // }
        // config.duration = duration;
        // // config.panelClass = ['custom-class'];
        // return this.snackBar.open(message, action, config);
        return this.showToastError(message);
    }

    openSnackBarNotifiChat(message: string, action: string = '', duration: number = 4000): MatSnackBarRef<SimpleSnackBar> {
        const config: any = new MatSnackBarConfig();
        config.panelClass = ['chat-notification-snackbar'];
        config.horizontalPosition = 'right';
        config.verticalPosition = 'bottom';
        config.duration = duration;
        return this.snackBar.open(message, action, config);
    }

    checkValidateForm(formGroup: FormGroup): boolean {
        if (!formGroup.valid) {

            let control: any = null;
            const controls: any = Object.keys(formGroup.controls);

            for (const c of controls) {
                control = formGroup.controls[c];
                if (!control.valid) {
                    control.markAsTouched();
                    control.markAsDirty();
                    control.updateValueAndValidity();
                }
            }
            return false;
        }
        return true;
    }

    removeValidateFormGroup(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(key => {
            if (!formGroup.controls[key].dirty) {
                formGroup.controls[key].setErrors(null);
            }
        });
    }

    checkValidateFormWithSpecificForm(formGroup: FormGroup): any {
        if (!formGroup.valid) {

            let control: any = null,
                nameControl: any = '';

            const controls: any = Object.keys(formGroup.controls);

            for (const c of controls) {
                control = formGroup.controls[c];
                if (!control.valid) {
                    if (nameControl.length === 0) {
                        nameControl = c;
                    }
                    control.markAsTouched();
                    control.markAsDirty();
                    control.updateValueAndValidity();
                }
            }
            const element: any = document.querySelector('[formcontrolname=' + nameControl + ']');
            if (element && typeof element['focus'] === 'function') {
                element['focus']();
            }
            return false;
        }
        return true;
    }

    showTranslateSnackbar(key: string, type: string = 'complete') {
        const message = this._translateService.translate(key);
        if (type === 'complete') {
            return this.showToastSuccess(message, '');
        }
        return this.showToastError(message, '');
    }

    translateMessage(key: string) {
        return this._translateService.translate(key);
    }

    showTranslateSnackbarMessage(message: string, type: string = 'complete') {
        // return this.openSnackBar(message, type);
        if (type === 'complete') {
            return this.showToastSuccess(message, '');
        }
        return this.showToastError(message, '');
    }

    openConfirmationDialogCancel(message: string, callback, title: string = '', label: string = 'Close', _dismissible: boolean = true): void {
        // Open the dialog and save the reference of it
        const configForm = {
            title: title,
            message: message,
            // icon       : this._formBuilder.group({
            //     show : true,
            //     name : 'heroicons_outline:exclamation',
            //     color: 'warn'
            // }),
            actions: {
                cancel: {
                    show: true,
                    label: label
                },
                confirm: {
                    show: false
                }
            },
            dismissible: _dismissible
        };
        const dialogRef = this._fuseConfirmationService.open(configForm);
        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            if (callback) {
                callback.call();
            }
        });
    }


    openConfirmationDialogCancelCallback(message: string, callbackOK, callbackCancel, title: string = '', labelOK: string = 'Submit',
        labelCancel: string = 'Close'): void {
        // Open the dialog and save the reference of it
        const configForm: FuseConfirmationConfig = {
            title: title,
            message: message,
            // icon       : this._formBuilder.group({
            //     show : true,
            //     name : 'heroicons_outline:exclamation',
            //     color: 'warn'
            // }),
            icon: {
                show: true,
                name: 'heroicons_outline:question-mark-circle',
                color: 'primary'
            },
            actions: {
                cancel: {
                    show: true,
                    label: labelCancel,
                    // color: 'primary'
                },
                confirm: {
                    show: true,
                    label: labelOK,
                    color: 'primary'
                    // labelOK: labelOK,
                }
            },
            dismissible: true
        };
        const dialogRef = this._fuseConfirmationService.open(configForm);
        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                if (callbackOK) {
                    callbackOK.call();
                }
            } else if (result === 'cancelled') {
                if (callbackCancel) {
                    callbackCancel.call();
                }
            }
        });
    }

    openConfirmationDialogCancelPromise(message: string, title: string = '', labelConfirm: string = 'Yes', label: string = 'No'): Promise<any> {
        // Open the dialog and save the reference of it
        return new Promise((resolve: any, reject: any) => {
            const configForm = {
                title: title,
                message: message,
                // icon       : this._formBuilder.group({
                //     show : true,
                //     name : 'heroicons_outline:exclamation',
                //     color: 'warn'
                // }),
                actions: {
                    cancel: {
                        show: true,
                        label: label
                    },
                    confirm: {
                        show: true,
                        label: labelConfirm
                    }
                },
                dismissible: true
            };
            const dialogRef = this._fuseConfirmationService.open(configForm);

            // Subscribe to afterClosed from the dialog reference
            dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    getAttachments(attachFormComponent): any {
        if (!attachFormComponent.jsf.isValid) {
            return [];
        }

        try {
            const data: any = attachFormComponent.jsf.validData;
            if (data.attachments) {
                const attachments: any[] = JSON.parse(data.attachments);
                return attachments.map(a => a.identifier);
            }
        } catch (e) {
            console.error(e);
            return [];
        }
        return [];
    }

    handleError(error): void {
        let errorObj = error.error;
        if (error.status === 400 && error.messageCode) {
            let messageCode = error.messageCode;
            let message = '';//this.translateService.instant(messageCode);
            if (message && message !== messageCode) {
                this.openSnackBar(message, 'error');
            } else {
                this.showTranslateSnackbar('VALIDATION.DATA', 'error');
            }
            return;
        } else if (error.status === 400
            && errorObj
            && errorObj.validation
            && errorObj.validation.messages) {
            let validateKey = '';
            let message = '';
            for (let obj of errorObj.validation.messages) {
                validateKey = obj;
            }
            if (validateKey) {
                message = 'VALIDATION.' + validateKey.toUpperCase();
                this.showTranslateSnackbar(message, 'error');
            } else {
                this.showTranslateSnackbar('VALIDATION.DATA', 'error');
            }
            return;
        } else if (error.status === 400
            && errorObj
            && errorObj.validation
            && errorObj.validation.validationResults) {
            var data = errorObj.validation;
            let validationResults = data.validationResults;
            let validateValue = '';
            let validateKey = '';
            let message = '';

            for (let key in validationResults) {
                if (validationResults[key]) {
                    validateKey = key;
                    validateValue = validationResults[key];
                    break;
                }
            }
            if (validateValue) {
                if (validateValue.indexOf(" ") > -1) {
                    String.prototype.replace
                    validateValue = validateValue.split(" ").join("");
                }
                if (validateKey.indexOf('[') > -1
                    && validateKey.indexOf(']') > -1
                    && validateKey.indexOf('.') > -1) {
                    let validateKeyArr = validateKey.split('.');
                    validateKey = validateKeyArr[validateKeyArr.length - 1];
                }
                message = 'VALIDATION.' + validateKey.toUpperCase() + '.' + validateValue.toUpperCase();
                this.showTranslateSnackbar(message, 'error');
            } else {
                this.showTranslateSnackbar('VALIDATION.DATA', 'error');
            }
            return;
        }

        this.showTranslateSnackbar('SYSTEM_ERROR', 'error');
    }

    openConfirmationDialog(message: string, callback, title: string = this._translateService.translate('GENERAL.LABEL_CONFIRM'), label: string = this._translateService.translate('ENVELOP.CLOSE'), labelConfirm: string = this._translateService.translate('ENVELOP.TITLE_CONFIRM')): void {
        const configForm = this._formBuilder.group({
            title: title,
            message: message,
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:question-mark-circle',
                color: 'primary'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: labelConfirm,
                    color: 'primary'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: label
                })
            }),
            dismissible: true
        });
        const dialogRef = this._fuseConfirmationService.open(configForm.value);

        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            if (callback) {
                callback(result);
            }
        });
    }

    openConfirmationDialogPromise(message: string, title: string = 'Confirm', label: string = 'Close', labelConfirm: string = 'Confirm'): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            const configForm = this._formBuilder.group({
                title: title,
                message: message,
                icon: this._formBuilder.group({
                    show: true,
                    name: 'heroicons_outline:question-mark-circle',
                    color: 'primary'
                }),
                actions: this._formBuilder.group({
                    confirm: this._formBuilder.group({
                        show: true,
                        label: labelConfirm,
                        color: 'primary'
                    }),
                    cancel: this._formBuilder.group({
                        show: true,
                        label: label
                    })
                }),
                dismissible: true
            });
            const dialogRef = this._fuseConfirmationService.open(configForm.value);

            // Subscribe to afterClosed from the dialog reference
            dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    getNameStatus(value: string) {
        if (value === STATUS_IN_DATABASE.ACTIVE) {
            return STATUS_IN_VIEW.ACTIVE
        } else if (value === STATUS_IN_DATABASE.UNACTIVE) {
            return STATUS_IN_VIEW.UNACTIVE
        }
        return null;
    }

    showColumnCommon(isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return [
                {
                    headerName: me._translateService.translate('GENERAL.STATUS'), field: 'recordStatus', minWidth: 130, filter: 'agSetColumnFilter',
                    filterParams: {
                        cellHeight: 20,
                        debounceMs: 1000,
                        applyButton: true,
                        suppressSyncValuesAfterDataChange: true,
                        suppressSorting: true,
                        suppressSelectAll: true,
                        cellRenderer: (data) => {
                            if (!data) {
                                return '';
                            }
                            if (!data.value) {
                                return '';
                            }
                            let value = data.value?.toUpperCase();
                            value = value === STATUS_IN_DB.ACTIVE?.toUpperCase() ? STATUS_IN_DATABASE.ACTIVE : STATUS_IN_DATABASE.UNACTIVE;
                            const status = this.getNameStatus(value);
                            if (!status) return "";
                            return this._translateService.translate('RECORD_STATUS.' + status);
                        }
                    },
                    valueGetter: (params) => {
                        if (params.data) {
                            const statusCode = params.data['recordStatus']?.toUpperCase();
                            const status = this.getNameStatus(statusCode);
                            if (!status) return "";
                            return me._translateService.translate('RECORD_STATUS.' + status);
                        }
                        return null;
                    }
                },
                {
                    headerName: me._translateService.translate('GENERAL.MAKER_DATE'), field: 'makerDate', minWidth: 200, filter: 'agDateColumnFilter',
                    filterParams: {
                        debounceMs: 1000,
                        applyButton: true,
                    },
                    cellRenderer: (data) => {
                        if (!data || !data?.value) {
                            return '';
                        }
                        return UtilCommon.formatDateFullTime(data.value);
                    }
                },
                {
                    headerName: me._translateService.translate('GENERAL.MAKER_ID'), field: 'makerId', minWidth: 250, filter: 'agTextColumnFilter',
                    filterParams: {
                        debounceMs: 1000,
                        applyButton: true,
                    },
                    cellRenderer: (data) => {
                        if (!data) {
                            return '';
                        }
                        if (!data.value) {
                            return '';
                        }
                        const value = data.value?.split('#');
                        const name = value.length >= 2 ? value[1] : value[0];
                        return name;
                    },
                },
                {
                    headerName: me._translateService.translate('GENERAL.CHECKER_DATE'), field: 'updateDate', minWidth: 200, filter: 'agDateColumnFilter',
                    filterParams: {
                        debounceMs: 1000,
                        applyButton: true,
                    },
                    cellRenderer: (data) => {
                        if (!data || !data?.value) {
                            return '';
                        }
                        return UtilCommon.formatDateFullTime(data.value);
                    }
                },
                {
                    headerName: me._translateService.translate('GENERAL.CHECKER_ID'), field: 'updateId', minWidth: 250, filter: 'agTextColumnFilter',
                    filterParams: {
                        debounceMs: 1000,
                        applyButton: true,
                    },
                    cellRenderer: (data) => {
                        if (!data) {
                            return '';
                        }
                        if (!data.value) {
                            return '';
                        }
                        const value = data.value?.split('#');
                        const name = value.length >= 2 ? value[1] : value[0];
                        return name;
                    },
                }
            ]
        }
        return [
            {
                headerName: me._translateService.translate('GENERAL.STATUS'), field: 'recordStatus', minWidth: 130,
                valueGetter: (params) => {
                    if (params.data) {
                        const statusCode = params.data['recordStatus']?.toUpperCase();
                        const status = this.getNameStatus(statusCode);
                        if (!status) return "";
                        return me._translateService.translate('RECORD_STATUS.' + status);
                    }
                    return null;
                }
            },
            {
                headerName: me._translateService.translate('GENERAL.MAKER_DATE'), field: 'makerDate', minWidth: 200,
                cellRenderer: (data) => {
                    if (!data || !data?.value) {
                        return '';
                    }
                    return UtilCommon.formatDateFullTime(data.value);
                }
            },
            {
                headerName: me._translateService.translate('GENERAL.MAKER_ID'), field: 'makerId', minWidth: 250,
                cellRenderer: (data) => {
                    if (!data) {
                        return '';
                    }
                    if (!data.value) {
                        return '';
                    }
                    const value = data.value?.split('#');
                    const name = value.length >= 2 ? value[1] : value[0];
                    return name;
                },
            },
            {
                headerName: me._translateService.translate('GENERAL.CHECKER_DATE'), field: 'updateDate', minWidth: 200,
                cellRenderer: (data) => {
                    if (!data || !data?.value) {
                        return '';
                    }
                    return UtilCommon.formatDateFullTime(data.value);
                }
            },
            {
                headerName: me._translateService.translate('GENERAL.CHECKER_ID'), field: 'updateId', minWidth: 250,
                cellRenderer: (data) => {
                    if (!data) {
                        return '';
                    }
                    if (!data.value) {
                        return '';
                    }
                    const value = data.value?.split('#');
                    const name = value.length >= 2 ? value[1] : value[0];
                    return name;
                },
            }
        ]
    }

    checkEndDateAfterStartDate(startDate: number, endDate: number) {
        if (endDate > startDate) return true;
        return false;
    }

    commonColumnNo(headerName: string, field: string, minWidth: number) {
        return {
            headerName: headerName, field: field, sortable: false, minWidth: minWidth,
        }
    }
    commonColumnAgGrid(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth
        }
    }

    commonColumnAgImgBtn(headerName: string, field: string, minWidth: number, onCellClicked?: (selfie) => void, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    if (!data || !data?.value) {
                        return '';
                    }
                    return `
                        <span>
                          <i class="pt-3 material-icons text-primary-500">image</i>
                        </span>
                    ` ;
                },
                cellStyle: { cursor: 'pointer' },
                onCellClicked
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                if (!data || !data?.value) {
                    return '';
                }
                return `
                    <span>
                      <i class="pt-3 material-icons text-primary-500">image</i>
                    </span>
                ` ;
            },
            cellStyle: { cursor: 'pointer' },
            onCellClicked
        }
    }

    commonColumnAgGridDate(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    if (!data || !data?.value) {
                        return '';
                    }
                    return UtilCommon.formatDateFullTime(data.value);
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                if (!data || !data?.value) {
                    return '';
                }
                return UtilCommon.formatDateFullTime(data.value);
            }
        }
    }
    commonColumnAgGridHourMinuteSecond(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    if (!data || !data?.value) {
                        return '';
                    }
                    return UtilCommon.formatDateTimeWithHourMinuteSecond(data.value);
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                if (!data || !data?.value) {
                    return '';
                }
                return UtilCommon.formatDateTimeWithHourMinuteSecond(data.value);
            }
        }
    }


    commonColumnAgGridWithNumber(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    return data.value ? data.value : '0'
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                return data.value ? data.value : '0'
            }
        }
    }

    commonColumnAgGridCustomId(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth, filter: 'agTextColumnFilter',
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    if (!data) {
                        return '';
                    }
                    if (!data.value) {
                        return '';
                    }
                    if (data.value.indexOf('#') > -1) {
                        const value = data.value?.split('#');
                        const name = value[1];
                        return name;
                    } else {
                        return data.value;
                    }
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                if (!data) {
                    return '';
                }
                if (!data.value) {
                    return '';
                }
                if (data.value.indexOf('#') > -1) {
                    const value = data.value?.split('#');
                    const name = value[1];
                    return name;
                } else {
                    return data.value;
                }
            }
        }
    }

    commonColumnAgGridWithRecordStatus(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth, filter: 'agSetColumnFilter',
                filterParams: {
                    cellHeight: 20,
                    debounceMs: 1000,
                    applyButton: true,
                    suppressSyncValuesAfterDataChange: true,
                    suppressSorting: true,
                    suppressSelectAll: true,
                    cellRenderer: (data) => {
                        if (!data) {
                            return '';
                        }
                        if (!data.value) {
                            return '';
                        }
                        let value = data.value.toUpperCase();
                        value = value === STATUS_IN_DB.ACTIVE.toUpperCase() ? STATUS_IN_DATABASE.ACTIVE : STATUS_IN_DATABASE.UNACTIVE;
                        const status = this.getNameStatus(value);
                        return this._translateService.translate('RECORD_STATUS.' + status);
                    }
                },
                valueGetter: (params) => {
                    if (params.data) {
                        const statusCode = params.data['recordStatus']?.toUpperCase();
                        const status = this.getNameStatus(statusCode);
                        return status ? me._translateService.translate('RECORD_STATUS.' + status) : null;
                    }
                    return null;
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            valueGetter: (params) => {
                if (params.data) {
                    const statusCode = params.data['recordStatus']?.toUpperCase();
                    const status = this.getNameStatus(statusCode);
                    return status ? me._translateService.translate('RECORD_STATUS.' + status) : null;
                }
                return null;
            }
        }
    }

    commonColumnAgGridWithFormatMoney(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    if ((!data || !data?.value) && data.value !== 0) {
                        return '';
                    }
                    return me._appState.formatNumberCurrencyExt(data.value);
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                if ((!data || !data?.value) && data.value !== 0) {
                    return '';
                }
                return me._appState.formatNumberCurrencyExt(data.value);
            }
        }
    }

    commonColumnAgGridFormatMoney(headerName: string, field: string, minWidth: number, isFilter: boolean = false) {
        const me = this;
        if (isFilter) {
            return {
                headerName: me._translateService.translate(headerName), field: field, filter: 'agTextColumnFilter', minWidth: minWidth,
                filterParams: {
                    debounceMs: 1000,
                    applyButton: true,
                },
                cellRenderer: (data) => {
                    return data.value ? this._appState.formatNumberCurrencyExt(data.value) : '';
                }
            }
        }
        return {
            headerName: me._translateService.translate(headerName), field: field, minWidth: minWidth,
            cellRenderer: (data) => {
                return data.value ? this._appState.formatNumberCurrencyExt(data.value) : '';
            }
        }
    }
}
