import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
    {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
    },
    //   {
    //     path: '**',
    //     // redirectTo: 'welcome',
    //     redirectTo: 'home',
    // },
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirm-sign', loadChildren: () => import('app/modules/auth/confirmation-signup/confirmation-signup.module').then(m => m.ConfirmationSingupModule) },
            { path: 'confirm-sign-social', loadChildren: () => import('app/modules/auth/confirm-register-social/confirm-register-social.module').then(m => m.ConfirmationSingupSocialModule) },
            { path: 'resetpass', loadChildren: () => import('app/modules/auth/confirm-forgot-pass/confirm-forgot-pass.module').then(m => m.ConfirmationForgotPassModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'accept-member', loadChildren: () => import('app/modules/profile/active-member/active-member.module').then(m => m.ActiveMemberModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
            { path: 'personal-policy', loadComponent: () => import('app/modules/public-text/personal-policy/personal-policy.component').then(m => m.PersonalPolicyComponent) },
            { path: 'term-of-use', loadComponent: () => import('app/modules/public-text/term-of-use/term-of-use.component').then(m => m.TermOfUseComponent) },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'checktoken', loadChildren: () => import('app/modules/common/check-token/check-token.module').then(m => m.CheckTokenModule) }
        ]
    },
    {
        path: '',
        // data: {
        //     layout: 'dense'
        // },
        canLoad: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/home/home.module').then(m => m.HomeModule) }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },
    // Admin routes
    {
        path: '',
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            { path: 'settings', loadChildren: () => import('app/modules/profile/menu-profile/menu-profile.module').then(m => m.MenuProfileModule) },
            { path: 'general', loadChildren: () => import('app/modules/setting/menu-general/menu-general.module').then(m => m.MenuGeneralModule) },
            { path: 'template', loadChildren: () => import('app/modules/template/menu-template/menu-template.module').then(m => m.MenuTemplateModule) },
            { path: 'report', loadChildren: () => import('app/modules/report/menu-report/menu-report.module').then(m => m.MenuReportModule) },
            { path: 'manage', loadChildren: () => import('app/modules/manage/menu-manage/menu-manage.module').then(m => m.MenuManageModule) },
            { path: 'notification', loadChildren: () => import('app/modules/notifications/notification/notification.module').then(m => m.NotificationModule) },
            {
                path: 'pages', children: [
                    // Authentication
                    // { path: 'authentication', loadChildren: () => import('app/modules/admin/pages/authentication/authentication.module').then(m => m.AuthenticationModule) },
                    // Coming Soon
                    { path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
                    // Error
                    {
                        path: 'error', children: [
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module) }
                        ]
                    },
                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
            // { path: '**', redirectTo: '404-not-found' }
        ]
    }
];