import { Injectable } from '@angular/core';
import { AgGridWithoutCacheStore } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.store';
import { USER_URL } from './user.constant';
import { UserService } from './user.service';
@Injectable()
export class UserStore extends AgGridWithoutCacheStore {
  public EVENT_PIVOT_COUNT = 'event:userCount';
  BASE_REQUEST_CONSTANT = {
    PIVOT_PAGING: USER_URL.SEARCH_USER,
    PIVOT_COUNT: USER_URL.PIVOT_COUNT,
    UPDATE_RECORD_STATUS: USER_URL.UPDATE_RECORD_STATUS
  };
  constructor(public _service: UserService) {
    super(_service);
  }
  public exportExcelCollectionTicket(columns: any): Promise<any> {
    let filterModel = this.lastRequest?.filterModel;
    let sortModel = this.lastRequest?.sortModel;
    let columnState = this.screenUserAttribute?.columnState;
    if (!!columnState) {
        for (let colState of columnState) {
            let isHide = colState.hide;
            if (isHide) {
                for (let col of columns) {
                    let colId = colState.colId;
                    let colName = col.name;
                    if (colId === colName) {
                        columns.splice(columns.indexOf(col), 1);
                        break;
                    }
                }
            }
        }
    }
    return this._service.exportExcelCollectionTicket(columns, filterModel, sortModel);
  }
}