
export class STATUS_IN_DATABASE {
    static ACTIVE:string = 'O';
    static UNACTIVE: string = 'C'
}


// tslint:disable-next-line: max-classes-per-file
export class STATUS_IN_VIEW {
    static ACTIVE:string = 'ACTIVE';
    static UNACTIVE: string = 'UNACTIVE'
}

// tslint:disable-next-line: max-classes-per-file
export class AUTH_STATUS {
    static MAKE: string = 'M';
    static ACCEPT: string = 'A';
}

// tslint:disable-next-line: max-classes-per-file
export class STATUS_IN_DB {
    static ACTIVE: string = 'Active';
    static UNACTIVE: string = 'Unactive'
}

// tslint:disable-next-line: max-classes-per-file
export class STATUS_BATCH_UPLOAD_TEMPLATE_IN_DB {
    static INIT: number = 0;
    static COMPLETED: number = 1;
    static IN_PROCESS: number = 2;
    static ERROR: number = -1;
    static FAILED: number = -2;
}

// tslint:disable-next-line: max-classes-per-file
export class STATUS_BATCH_UPLOAD_TEMPLATE_IN_VIEW {
    static INIT: string = 'Init';
    static COMPLETED: string = 'Completed';
    static IN_PROCESS: string = 'InProcess';
    static ERROR: string = 'Error';
    static FAILED: string = 'Failed';
}

