export class TYPE_TREE {
    static READ_ONLY: string = 'READ_ONLY';
}

export class TIMER {
    static TIMER_FOR_BUILD_COLUMN_AG_GRID: number = 300;
    static ONE_MINUTE: number = -60;
    static ONE_WEEK: number = 7;
    static NINETY_DAY: number = 90;
}

export class SCREEN {
    static UNIT_SCREEN: string = 'UNIT_SCREEN';
    static EMPLOYEE_SCREEN: string = 'EMPLOYEE_SCREEN';
}

export class USER_SETTING {
    static FORMAT_DAY_MONTH_YEAR_DATE_TIME: string = 'DD/MM/YYYY HH:mm:ss';
    static FORMAT_DAY_MONTH_YEAR_DATE: string = 'DD/MM/YYYY';
    static FORMAT_TIME: string = 'HH:mm:ss';
    static FORMAT_MONTH_DAY_YEAR_DATE_TIME: string = 'MM/DD/YYYY HH:mm:ss';
    static FORMAT_MONTH_DAY_YEAR_DATE: string = 'MM/DD/YYYY';
}

export class COMMON_CONSTANT {
    static readonly MENU_ORGANIZATION: string = 'general';
}

export class COMMON_VARIABLE {
    static ALL_CODE: string = 'ALL';
    static readonly USER_NOT_FOUND: string = 'USER_NOT_FOUND';
    static readonly ERROR_MAX_SESSION: string = 'ERROR_MAX_SESSION';
    static readonly CUSTOM_DOMAIN_INVALID: string = 'CUSTOM_DOMAIN_INVALID';
}