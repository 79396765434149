import { NgModule } from '@angular/core';
import { DateTimeFormatPipe } from './dateTimeFormat.pipe';
import { DateFormatPipe } from './dateFormat.pipe';
import { KeepHtmlPipe } from './keepHTML.pipe';
import { CurrencyFormat } from './currentcyFormat.pipe';
@NgModule({
    declarations: [
        DateTimeFormatPipe,
        DateFormatPipe,
        KeepHtmlPipe,
        CurrencyFormat
    ],
    imports: [],
    exports: [
        DateTimeFormatPipe,
        DateFormatPipe,
        KeepHtmlPipe,
        CurrencyFormat
    ]
})
export class PipesModule {


}
