import { ErrorHandler, Injectable, inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { CloudWatchLoggerService } from "./cloud-watch-log.service";
import { toHttpErrorLog, toProgramlog } from "./logs.util";
import { environment } from "environments/environment";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    private _awsCloudWatchService: CloudWatchLoggerService = inject(CloudWatchLoggerService);

    public handleError(error: any): void {
        if (!(error instanceof HttpErrorResponse)) {
            this._awsCloudWatchService.logError(toProgramlog(error))
        }
        if (!environment.isWriteLogCW){
            console.error('err',error)
        }
        // else if (error instanceof HttpErrorResponse) {
        //     this._awsCloudWatchService.logError(toHttpErrorLog(error))
        // }
    }
}