import { Injectable } from '@angular/core';
import { NotificationConstant } from 'app/layout/common/notifications/notification.constant';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageNotificationService {
  aggIdUrl: string = null;
  public _isTriggerPageNotification: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private _httpHelper: HttpHelper) { }
  async getPagingListService(keyword, startRow: number, numOfRow: number): Promise<any> {
    const loadCfg = {
        startRow,
        numOfRow,
        filters: [
            {
                field: "keyword",
                value: keyword,
                comparison: "eq",
                type: "string"
            }
        ],
        sortField: "",
        sortDir: "DESC"
    };
    return this._httpHelper.methodPostService(NotificationConstant.GET_PAGING_LIST_NOTIFICATIONS, loadCfg);
  }

  setAggId(aggId: string) {
    this.aggIdUrl = aggId;
  }

  getAggId() {
    return this.aggIdUrl;
  }

  async getDetailNotificationService(aggId: string): Promise<any> {
    return this._httpHelper.methodGetService(NotificationConstant.GET_DETAIL_NOTIFICATION + aggId);
  }

  triggerAPIPagingPageNotification(status: boolean) {
    this._isTriggerPageNotification.next(status);
  }

  async getCountListService(keyword): Promise<any> {
    const loadCfg = {
        filters: [
            {
                field: "keyword",
                value: keyword,
                comparison: "eq",
                type: "string"
            }
        ],
        sortField: "",
        sortDir: "DESC"
    };
    return this._httpHelper.methodPostService(NotificationConstant.PIVOT_COUNT, loadCfg);
  }
}
