import { PROFILE_USER_URL } from './profile-user.constant';
import { HttpHelper } from './../../../shared/common/utils/http/HttpHelper';
import { Injectable } from '@angular/core';
import { ICreateEmpResource, IUpdateUser } from './profile-user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileUserService {
  constructor(
    private _httpHelper: HttpHelper
  ) { }

  getEmpResource(group: string): Promise<any> {
    return this._httpHelper.methodGetService(PROFILE_USER_URL.GET_EMP_RESOURCE + group);
  }

  updateProfileUser(data: IUpdateUser): Promise<any> {
    return this._httpHelper.methodPostService(PROFILE_USER_URL.UPDATE_USER, data);
  }

  createEmpResource(data: ICreateEmpResource): Promise<any> {
    return this._httpHelper.methodPostService(PROFILE_USER_URL.CREATE_EMP_RESOURCE, data);
  }

  listIndustryService(type: string): Promise<any> {
    return this._httpHelper.methodGetService(PROFILE_USER_URL.LIST_INDUSTRY + type);
  }
}
