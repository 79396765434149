import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
// import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
// import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfigFuse } from './core/config/app.config.fuse';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './shared/common/utils/http/HttpInterceptor.service';
import { TDSetFilterComponent } from './shared/components/aggrid/ag-grid-select-filter/td-set-filter.component';
import 'ag-grid-enterprise';
import { AgGridModule } from 'ag-grid-angular';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import Amplify from 'aws-amplify';
import { Amplify } from "aws-amplify";
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { GlobalErrorHandlerService } from './shared/common/config/config-cloud-watch/global-error-handler.service';
import { AWS_CLOUD_WATCH_CLIENT_CONFIG, AWS_STREAM_CONFIG } from './shared/common/config/config-cloud-watch/aws-config.di';
Amplify.configure(environment.awsmobile);
// const routerConfig: ExtraOptions = {
//     preloadingStrategy: PreloadAllModules,
//     scrollPositionRestoration: 'enabled'
// };

export function providers(): any {
    return [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: AWS_STREAM_CONFIG,
            useValue: {
                logGroupName: environment.awsCloudWatch.logGroupName,
                logStreamName: environment.awsCloudWatch.logStreamName,
            }
            },
        {
            provide: AWS_CLOUD_WATCH_CLIENT_CONFIG,
            useValue: {
                awsAccessKey: environment.awsCloudWatch.awsAccessKey,
                awsSecretKey: environment.awsCloudWatch.awsSecretKey,
                awsRegion: environment.awsCloudWatch.awsRegion
            }
        }
    ];
}

@NgModule({
    declarations: [
        AppComponent, TDSetFilterComponent
    ],
    imports: [
        AgGridModule.withComponents([TDSetFilterComponent]),
        BrowserModule,
        BrowserAnimationsModule,
        // AmplifyUIAngularModule,
        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'reload'
        }),
        // RouterModule.forRoot(appRoutes, routerConfig),
        MatSnackBarModule,
        HttpClientModule,
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: HttpLoaderFactory,
        //         deps: [HttpClient],
        //     },
        // }),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfigFuse),
        // FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            maxOpened: 1
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        providePerformance(() => getPerformance()),
        // AngularFireDatabaseModule,
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFirestoreModule
    ],
    providers: providers(),
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
