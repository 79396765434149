import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AppStateConstant } from 'app/shared/common/constants/AppState.constant';
import { AppState } from 'app/shared/common/utils/AppState';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _appState: AppState,
        private _utilsComp: UtilComponent,
        private _translateService: TranslocoService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, state);
    }

    // /**
    //  * Can load
    //  *
    //  * @param route
    //  * @param segments
    //  */
    // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    // {
    //     return this._check('/');
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string, state: RouterStateSnapshot): Observable<boolean> | boolean
    {
        const urlCurrent = location.hash;
        let isLoggedIn: boolean = this._appState.isLoggedIn();
        // Thong bao o day, check permission
        if (!isLoggedIn) {
            if (this._router.url.indexOf('sign-in') > -1) {
                return isLoggedIn;
            }
            this._router.navigate(['/sign-in']);
            return isLoggedIn;
        }
        const url: string = state.url;
        // Check xem user co the truy cap page hay khong
        isLoggedIn = this._appState.accessPage(url);
        //// bo check page
        if (url.indexOf('welcome') > -1
        || url.indexOf('home') > -1
            || url.indexOf('login') > -1
            || url.indexOf('notification') > -1
            || url.indexOf('fe-dashboard') > -1
        ) {
            isLoggedIn = true;
        }
        if (!isLoggedIn) {
            this._utilsComp.generateDialogAlert(this._translateService.translate('MESSAGE_ERROR.ACCESS_DENY_PAGE'));
            return isLoggedIn;
        }
        return isLoggedIn;
    }
    canLoad(): boolean {
        if (localStorage.getItem(AppStateConstant.userName)) {
            return true;
        }
        this._router.navigate(['/sign-in']);
        return false;
    }
}
