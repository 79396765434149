import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  setExtra(error: any, endpoint: string, message: string, apiParam?: any) {
    // Sentry. configureScope((scope) => {
    //   scope.setExtra('Error', error);
    //   scope.setExtra('Api', endpoint);
    //   scope. setExtra('Error Message', message);
    //   if (apiParam) scope.setExtra('Param', apiParam);
    // });
  }

  setUser(username: string) {
    // Sentry.setUser({ username });
  }
}
