import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from 'app/shared/common/utils/AppState';

@Component({
  selector: 'app-popup-help-page',
  templateUrl: './popup-help-page.component.html',
  styleUrls: ['./popup-help-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupHelpPageComponent implements OnInit {
  showIframes: boolean;
  _previewUrls: any;
  loading: boolean = true;

  constructor( public matDialogRef: MatDialogRef<PopupHelpPageComponent>, 
    private changeRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private appState: AppState) {
    this._previewUrls = this.sanitizer.bypassSecurityTrustResourceUrl('assets/help/User Manual.html');
   }
  ngOnInit(): void {
    this.showIframes = true;
  }

  closeModal(): void {
    this.matDialogRef.close();
  }

}
