import { Injectable } from '@angular/core';
import { APP_CONFIG } from 'app.config';
import { AppState } from 'app/shared/common/utils/AppState';
import { ExcelService } from 'app/shared/common/utils/ExcelService';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { AgGridWithoutCacheService } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.service';
import { USER_URL } from './user.constant';
import { IAssginRole, ICreateUser } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AgGridWithoutCacheService {
  constructor(
    private _httpHelper: HttpHelper,
    public _appState: AppState,
    private excelService: ExcelService

  ) {
    super(_httpHelper, _appState)
  }

  createUser(data: ICreateUser): Promise<any> {
    return this._httpHelper.methodPostService(USER_URL.CREATE_USER, data);
  }

  updateUser(data: ICreateUser): Promise<any> {
    return this._httpHelper.methodPostService(USER_URL.UPDATE_USER, data);
  }

  getByAggId(aggId: string): Promise<any> {
    return this._httpHelper.methodGetService(USER_URL.GET_BY_AGG_ID + aggId);
  }

  getRolesByAggId(aggId: string): Promise<any> {
    return this._httpHelper.methodGetService(USER_URL.GET_ROLE_BY_AGGID + aggId);
  }

  getAllRoles(): Promise<any> {
    return this._httpHelper.methodGetService(USER_URL.GET_ALL_ROLE);
  }

  assignRoleOfUser(data: IAssginRole): Promise<any> {
    return this._httpHelper.methodPostService(USER_URL.ASSIGN_ROLE_USER, data);
  }

  quickSearchUser(keyword: string, searchOption?: string, numOfResult: number = 50): Promise<any> {
    keyword = UtilCommon.removeVietnameseTones(keyword);
    let params = '';
    if (!keyword) {
      params = `?keyword=&numOfResult=${numOfResult}`;
    } else {
      params = `?keyword=${keyword}&numOfResult=${numOfResult}`;
    }
    return this._httpHelper.methodGetService(USER_URL.QUICK_SEARCH_USER + params);
  }

  getLinkSetupPasswordService(email: string): Promise<any> {
    let params = `?email=${email}`;
    return this._httpHelper.methodGetService(USER_URL.LINK_SETUP_PASSWORD + params);
  }

  getLinkResetPasswordService(email: string): Promise<any> {
    let params = `?email=${email}`;
    return this._httpHelper.methodGetService(USER_URL.LINK_RESET_PASSWORD + params);
  }

  public exportExcelCollectionTicket(columns: any, filterModel: any, sortModel: any): Promise<any> {
    const params = {
      startRow: null,
      endRow: null,
      columns: columns,
      filterModel: filterModel,
      sortModel: sortModel,
    };
    const url = `${APP_CONFIG.MCRUS_PROFILE_API}report/exportUserProfile`;

    return this.excelService.exportWithColumnsUrl(url, params, `collection_${new Date().getTime()}.xlsx`)
      .then(result => {
        return result;
      }).catch(error => {
        throw error;
      });
  }
}
