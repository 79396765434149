import { Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import cloneDeep from 'lodash-es/cloneDeep';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import { Events } from 'app/shared/common/utils/Events';
import { IPivotPagingResponse } from './ag-grid-without-cache.model';
import { AgGridWithoutCacheService } from './ag-grid-without-cache.service';
import { STATUS_IN_DATABASE, STATUS_IN_DB } from 'app/shared/common/constants/Status.constant';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class AgGridWithoutCacheStore {
    public isFirstSetUserAttribute = true;
    public EVENT_PIVOT_COUNT = 'event:jobTitleCount';
    public scrollTopPage = 0;
    public isLoading = false;
    public screenUserAttribute: any;
    public fullText = '';
    public filterObj: any;
    public isRefreshFilter = false;
    protected lastRequest: any;
    public normalFilterModel: any;
    public columnState;
    public rowNumber: number = -1;
    public BASE_REQUEST_CONSTANT;
    public datasource: any;
    public searchOption: string;

    constructor(protected _service: AgGridWithoutCacheService) {
    }

    async pivotPaging(request: any): Promise<IPivotPagingResponse> {
        const statusFilterValue = request?.filterModel?.recordStatus?.values[0];
        if (statusFilterValue) {
            if (statusFilterValue === STATUS_IN_DB.ACTIVE) {
                request.filterModel.recordStatus.values[0] = STATUS_IN_DATABASE.ACTIVE;
            } else {
                request.filterModel.recordStatus.values[0] = STATUS_IN_DATABASE.UNACTIVE;
            };
        };
        this.normalFilterModel = request?.filterModel;
        request = this.buildFullTexSearchRequest(request, this.fullText);
        this.lastRequest = request;
        this.isLoading = true;
        const countData = await this.pivotCount(this.BASE_REQUEST_CONSTANT?.PIVOT_COUNT, this.isRefreshFilter);
        this.isRefreshFilter = false;
        if (UtilCommon.isNotNull(countData)) {
            this.rowNumber = countData;
        }
        return this._service.pivotPaging(this.transformRequestBeforeQuery(request), this.BASE_REQUEST_CONSTANT?.PIVOT_PAGING)
            .then(async (data) => {
                this.isLoading = false;
                if (data && !data.isNotNotified) {
                    // this._storeData = data;
                    // Count
                    Events.publish(this.EVENT_PIVOT_COUNT, this.rowNumber);
                }
                if (data.isNotNotified) {
                    this.rowNumber = 0;
                    Events.publish(this.EVENT_PIVOT_COUNT, this.rowNumber);
                }
                return data;
            }).catch(error => {
                this.isLoading = false;
                throw error;
            });
    }

    async pivotCount(apiCount: string, isRefreshFilter: boolean = false): Promise<any> {
        const lastRequest = Object.assign({}, this.lastRequest);
        if (lastRequest?.startRow !== 0 && !isRefreshFilter) {
            return Promise.resolve(this.rowNumber);
        }
        delete lastRequest?.startRow;
        delete lastRequest?.endRow;
        return this._service.pivotCount(lastRequest, apiCount);
    }

    protected buildFullTexSearchRequest(request: any, fullText: string = ''): any {
        let filterModel: any = request.filterModel;
        if (this.fullText && this.fullText.length > 0) {
            filterModel = {
                [this.searchOption ?? 'ftsValue']: {
                    type: 'equals',
                    filter: fullText,
                    filterType: 'text'
                }
            };
        } else if (this.searchOption && this.fullText && this.fullText.length > 0) {
            filterModel = {
                [this.searchOption ?? 'ftsValue']: {
                    type: 'equals',
                    filter: fullText,
                    filterType: 'text'
                }
            };
        }
        return {
            ...request,
            filterModel,
        };
    }

    protected transformRequestBeforeQuery(request: any): any {
        const mapChangeCols = {};
        if (!isEmpty(mapChangeCols)) {
            request = cloneDeep(request);
            const { filterModel, sortModel } = request;

            forEach(mapChangeCols, (toCol, fromCol) => {
                if (filterModel[fromCol]) {
                    filterModel[toCol] = filterModel[fromCol];
                    delete filterModel[fromCol];
                }

                const sortCol = find(sortModel, m => m.colId === fromCol);
                if (sortCol) {
                    sortCol.colId = toCol;
                }
            });
        }
        return request;
    }

    private buildUserAttribute(): any {
        return {
            fullText: this.fullText || '',
            filterObj: this.filterObj || null,
            columnState: this.columnState || []
        };
    }

    getUserAttribute(): Promise<any> {
        let localStorageUserAttribute = localStorage.getItem(this.BASE_REQUEST_CONSTANT?.PAGE_ATTRIBUTE);
        if (localStorageUserAttribute && localStorageUserAttribute !== 'null') {
            localStorageUserAttribute = JSON.parse(localStorageUserAttribute);
            this.columnState = localStorageUserAttribute['columnState'];
            this.fullText = localStorageUserAttribute['fullText'];
            this.filterObj = localStorageUserAttribute['filterObj'];
            return Promise.resolve();
        }
        return this._service.getUserAttribute(this.BASE_REQUEST_CONSTANT?.PAGE_ATTRIBUTE).then(userAttribute => {
            this.columnState = userAttribute?.columnState;
            this.fullText = userAttribute?.fullText;
            this.filterObj = userAttribute?.filterObj;
        });
    }

    setUserAttribute(): Promise<any> {
        const userAttribute = JSON.stringify(this.buildUserAttribute());
        // console.log(this.BASE_REQUEST_CONSTANT?.PAGE_ATTRIBUTE);
        // console.log(userAttribute);

        localStorage.setItem(this.BASE_REQUEST_CONSTANT?.PAGE_ATTRIBUTE, userAttribute);
        return this._service.setUserAttribute(this.BASE_REQUEST_CONSTANT?.PAGE_ATTRIBUTE, userAttribute);
    }
}