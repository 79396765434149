import { Injectable } from "@angular/core";
import { API_URL, APP_CONFIG, LOGIN_URL } from "app.config";
import { AppStateConstant } from "../constants/AppState.constant";
import { EventsConstant } from "../constants/Events.constant";
import { Events } from "./Events";
import { HttpHelper } from "./http/HttpHelper";
import { UtilCommon } from "./UtilCommon";
import { Auth } from 'aws-amplify';
import { MOMENT_DATE_FORMAT } from "../config/MomentDateFormat.config";
import { HomeService } from "app/modules/home/home.service";
import { FireStoreService } from "./services/firestore.service";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AppState {
    appState: any = undefined;
    pageSize = 0;
    menuData: any[] = [];
    openMenu: boolean;
    scrollTopMenu: number;
    userAttributeValues: any;
    userInfo;
    userAvatar;
    unitInfo;
    lstBookMark = [];
    accessToken = '';
    idToken = '';
    refreshToken = '';
    serverToken = '';
    isMobile = true;
    callFromSignPage = false;
    profileSettings;
    tokenSignPage;
    settingsApp = {};
    constructor(
        private httpHelper: HttpHelper,
        private homeService: HomeService,
        private _fireStoreService: FireStoreService,
    ) {
        this.menuData = [];
        this.pageSize = APP_CONFIG.LIMIT_QUERY;
        let appState: any = localStorage.getItem(AppStateConstant.appState);
        if (appState) {
            appState = JSON.parse(appState);
            this.setAppState(appState);
        }
        this.openMenu = true;
        this.scrollTopMenu = 0;
        this.userInfo = null;
        this.userAvatar = null;
        this.settingsApp = UtilCommon.genSettingsApp();

    }

    setAppState(appState: any): void {
        this.appState = appState;
        if (appState) {
            this.setUserInfo(appState);
            Events.publish(EventsConstant.LoadUserInfo, appState);
            localStorage.setItem(AppStateConstant.appState, JSON.stringify(appState));
        }
        (<any>window).ICRM_APP_STATE = appState;
    }

    getUrlCurrent(): string {
        return localStorage.getItem('urlCurrent');
    }

    setUrlCurrent(value): void {
        localStorage.setItem('urlCurrent', value.toString().replace('#', ''));
    }

    getAppState(): any {
        try {
            if (this.appState) {
                return this.appState;
            }
            const app = localStorage.getItem(AppStateConstant.appState);
            if (app) {
                this.appState = JSON.parse(app);
            }
        } catch (e) {

        }
    }

    getAppStateUsername(): string {
        return localStorage.getItem(AppStateConstant.userName);
    }

    setAppStateUsername(username: any): void {
        localStorage.setItem(AppStateConstant.userName, username);
    }

    getLanguage(): string {
        const profileSettings = this.appState?.profileSettings;
        if (profileSettings) {
            const language = profileSettings?.language;
            if (language) {
                localStorage.setItem(AppStateConstant.Language, language);
                return language;
            }
        }
        return localStorage.getItem(AppStateConstant.Language) || 'vi';
    }

    setLanguage(language: string): void {
        if (this.getAppState()) {
            this.getAppState().langKey = language;
        }
        localStorage.setItem(AppStateConstant.Language, language);
    }

    changeLang(language: string): void {
        // this.translateService.use(language);
        this.setLanguage(language);
        // this.translateService.getTranslation(language);
        // moment.locale(language);
    }

    isLoggedIn(): boolean {
        return this.getAppState() ? true : false;
    }

    async clearLogout(isLogoutCognito, isLogApi: boolean = true): Promise<void> {
        try {
            const username = this.getAppStateUsername();
            let language: string = localStorage.getItem('language');
            let version: string = localStorage.getItem('version');
            localStorage.clear();
            if (!language || language === 'null') {
                language = 'vi';
            }
            if (!version || version === 'null') {
                version = APP_CONFIG.VERSION;;
            }
            localStorage.setItem('version', version);
            localStorage.setItem('language', language);
            this.menuData = [];
            this.openMenu = true;
            this.scrollTopMenu = 0;
            this.profileSettings = null;
            window[AppStateConstant.appState] = null;
            sessionStorage.clear();
            this.homeService.clearData();
            this.setAppState(null);
            this.isMobile = false;
            window['isMobile'] = false;
            if (isLogoutCognito) {
                Auth.signOut();
            }
            if (isLogApi) {
                this.logoutApi(username);
            }
            this._fireStoreService.logout();
        } catch (e) {
        }
    }

    logoutApi(username: string): Promise<any> {
        const loginObject: any = {
            'username': username
        };
        return this.httpHelper.methodPostJsonService(APP_CONFIG.LOGOUT, loginObject, APP_CONFIG.COMMAND_TIME_OUT);
    }

    // Lưu user atribute ở đây
    getPageSizeOptions(): any[] {
        return [20, 50, 100, 200];
    }

    getPageSize(): number {
        return this.pageSize;
    }

    getPageDefault() {
        return 'home';
    }

    // Kiểm tra xem bạn được quyền truy cập vao page nao
    accessPage(accessLink: string): boolean {
        if (!this.appState) {
            return true;
        }
        if (!this.getScreen()) {
            return true;
        }
        const pages: any = this.getScreen();
        if (pages) {
            for (const page of pages) {
                for (const ch of page.children) {
                    for (const item of ch.children) {
                        if (UtilCommon.isArray(item.children)) {
                            for (const itemChild of item.children) {
                                if (accessLink.indexOf(itemChild.link) > -1) {
                                    return true;
                                }
                            }
                        }
                        else if (accessLink.indexOf(item.link) > -1) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        // Hien tai bo qua ham nay cho scren hien thi
        return true;
    }

    // Kiêm tra xem bạn được quyền sử dụng những function nào
    accessFunction(accessLink: string, functionName: string): boolean {
        if (!this.appState) {
            return true;
        }
        const pages: any = this.getScreen();
        let functions: any = null;

        for (const page of pages) {
            if (!page.route) {
                continue;
            }
            if (accessLink.indexOf(page.route) > -1) {
                functions = page.func;
                if (functions) {
                    return functions[functionName];
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    // Kiêm tra xem bạn được quyền sử dụng những function nào
    hasPermission(functionName: string): boolean {
        if (!this.appState) {
            return false;
        }
        if (this.appState.permissions) {
            for (const per of this.appState.permissions) {
                if (per.target === functionName) {
                    return true;
                }
            }
        }
        return false;
    }

    hasRole(roleName: string): boolean {
        if (!this.appState) {
            return false;
        }
        if (this.appState.authorities) {
            for (const per of this.appState.authorities) {
                if (per.authority === roleName) {
                    return true;
                }
            }
        }
        return false;
    }

    setUserInfo(userInfo: any): void {
        this.userInfo = userInfo;
    }

    setScreen(menuData: any): void {
        this.appState.screen = menuData;
    }

    getScreen(): any {
        if (this.appState) {
            return this.appState.screen;
        }
        return null;
    }

    getCompanyCode(): any {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return '';
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return '';
        }
        return userInfo.tenantCode;
    }

    getAppCode(): any {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return '';
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return '';
        }
        return userInfo.appCode;
    }

    getUserId(): number {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return -1;
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return -1;
        }
        return userInfo.userId;
    }

    getAttributeValues(): any {
        return this.userAttributeValues || {};
    }

    getUserinfo(): any {
        return this.appState;
    }

    getAttributeValue(attributeName: string): Promise<any> {
        const url: string = API_URL.ATTRIBUTE.GET + `?attribute=${encodeURIComponent(attributeName)}`;
        return this.httpHelper.methodGetService(url, APP_CONFIG.QUERY_TIME_OUT);
    }

    setAttributeValue(attributeName: string, atributeValue: string): Promise<any> {
        const request = {
            'attribute': attributeName,
            'attrValue': atributeValue
        };
        const url: string = API_URL.ATTRIBUTE.SET;
        const params = `${JSON.stringify(request)}`;
        return this.httpHelper.methodPostJsonService(url, params, APP_CONFIG.QUERY_TIME_OUT);
    }

    getUserInfo(): Promise<any> {
        return this.httpHelper.methodGetService(LOGIN_URL.USER_INFO);
    }

    getMenuTree(): Promise<any> {
        const url: string = LOGIN_URL.MENU;
        // let url: string = 'assets/data/menu.json';
        // if(!UtilCommon.isCRMApp()){
        //     url = 'assets/data/menu_hunt.json';
        // }
        return this.httpHelper.methodGetService(url);
    }

    logout(username: string): Promise<any> {
        const urlLogout: string = APP_CONFIG.HOST_NAME_LOGIN + APP_CONFIG.LOGOUT_SERVICE,
            params: string = 'username=' + username;
        return this.httpHelper.actionLogOut(urlLogout, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getTokenFormServer(accessToken: string, idToken: string,): Promise<any> {
        const url: string = LOGIN_URL.GET_TOKEN;
        const params = {
            "idToken": idToken,
            "accessToken": accessToken,
            'appCode': APP_CONFIG.APP_CODE
        }
        return this.httpHelper.methodPostJsonService(url, params, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getProfileInfoNew(): Promise<any> {
        const url: string = LOGIN_URL.GET_PROFILE_NEW;
        return this.httpHelper.methodGetService(url, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getAccessToken(): string {
        if (this.accessToken) {
            return this.accessToken;
        }
        return localStorage.getItem(AppStateConstant.AccessToken);
    }

    getIdToken(): string {
        if (this.idToken) {
            return this.idToken;
        }
        return localStorage.getItem(AppStateConstant.IdToken);
    }

    getServerToken(): string {
        if (this.serverToken) {
            return this.serverToken;
        }
        return localStorage.getItem(AppStateConstant.ServerToken);
    }

    getRefreshToken(): string {
        if (this.refreshToken) {
            return this.refreshToken;
        }
        return localStorage.getItem(AppStateConstant.RefreshToken);
    }

    async logoutCognito() {
        try {
            const dataLogin = await Auth.signOut();
            if (UtilCommon.isNotNull(dataLogin)) {
            }
        } catch (e) {
        }
    }

    async getSession() {
        try {
            const dataSession = await Auth.currentSession();
            if (UtilCommon.isNotNull(dataSession)) {
                if (dataSession.isValid()) {
                    const accessToken = dataSession?.getAccessToken().getJwtToken();
                    const idToken = dataSession?.getIdToken().getJwtToken();
                    const refreshToken = dataSession?.getRefreshToken().getToken();
                    this.accessToken = accessToken;
                    this.idToken = idToken;
                    this.refreshToken = refreshToken;
                    localStorage.setItem(AppStateConstant.AccessToken, accessToken);
                    localStorage.setItem(AppStateConstant.IdToken, idToken);
                    localStorage.setItem(AppStateConstant.RefreshToken, refreshToken);
                }
                // het session
            }
            // console.log(dataLogin);
        } catch (e) {
        }
    }

    setEnvelopId(evelopId, isClear) {
        if (isClear) {
            sessionStorage.setItem('evelopAggId', '');
            return;
        }
        sessionStorage.setItem('evelopAggId', evelopId);
    }

    checkRoutePassLogin(hash) {

        if (hash.indexOf('sign-document/sign/') > -1) {
            return false;
        }
        if (['accept-member', 'not-found', 'forgot-password', 'sign-up', 'sign-in', 'checktoken',
            'confirm-sign', 'sign-document', 'resetpass', 'confirm-sign-social', 'reset-password', 'unlock-session',  'personal-policy', 'term-of-use', 'term-of-aml']
            .findIndex(h => hash.indexOf(h) >= 0) >= 0) {
            return true;
        }
        return false;
    }

    getDateTimeFromProfileSetting() {
        const profileSettings = this.getAppState()?.moreInfo?.profileSetting;
        if (!profileSettings) {
            return MOMENT_DATE_FORMAT.FULL_TIME;
        }
        const timeFormat = profileSettings?.formatSetting?.date?.toUpperCase() + ' ' + profileSettings?.formatSetting?.time;
        if (timeFormat === 'undefined undefined' || !timeFormat || timeFormat === 'undefined') {
            return MOMENT_DATE_FORMAT.FULL_TIME;
        }
        return timeFormat ?? MOMENT_DATE_FORMAT.FULL_TIME;
    }

    getDateFromProfileSetting() {
        const profileSettings = this.getAppState()?.moreInfo?.profileSetting;
        if (!profileSettings) {
            return MOMENT_DATE_FORMAT.ONLY_DATE;
        }
        const dateFormat = profileSettings?.formatSetting?.date;
        if (dateFormat) {
            return dateFormat[0];
        }
        const dateTime = profileSettings?.formatSetting?.dateTime?.toString()?.split(' ');
        if (UtilCommon.checkISArray(dateTime) && dateTime.length == 1) {
            return dateTime[0];
        }
        return MOMENT_DATE_FORMAT.ONLY_DATE;
    }

    getLocaleFromProfileSetting() {
        return 'en-US';
    }

    handleMenu(menus) {
        const menu = [];
        for (const m of menus) {
            if (m.menuCode !== 'APPLICATION.6') {
                const menuChild = m.children;
                if (UtilCommon.isArray(menuChild)) {
                    const mChild = menuChild[0].children;
                    if (UtilCommon.isArray(mChild)) {
                        menu.push(m);
                    }
                }
            }
        }
        return menu;
    }

    handleMenuParent(menuCodeParent) {
        let menuChild = this.getScreen();
        if (!menuChild && localStorage.getItem('menuFinal')) {
            menuChild = JSON.parse(localStorage.getItem('menuFinal'));
        }
        const menuData = [];
        if (UtilCommon.isArray(menuChild)) {
            for (const menu of menuChild) {
                if (menu.menuCode === menuCodeParent) {
                    const menuChild = menu.children;
                    if (UtilCommon.checkISArray(menuChild)) {
                        for (const mParent of menuChild) {
                            const parentMenu = 'menu.' + mParent.menuCode;
                            const menuTemp =
                            {
                                id: parentMenu,
                                title: 'MENU.' + mParent.title,
                                type: 'group',
                                children: [
                                ]
                            };
                            const menuChildChildren = mParent.children;
                            if (UtilCommon.checkISArray(menuChildChildren)) {
                                for (const mChild of menuChildChildren) {
                                    menuTemp.children.push({
                                        id: parentMenu + '.' + mChild.menuCode,
                                        title: 'MENU.' + mChild.menuName,
                                        type: 'basic',
                                        link: '/' + UtilCommon.handleHistoryToken(mChild.historyToken)
                                    })
                                }
                            }
                            menuData.push(menuTemp);
                        }
                    }

                }
            }
        }
        return menuData;
    }

    checkPermission(functionName: string): boolean {
        const permissions = this.appState?.permissions;
        if (UtilCommon.isArray(permissions)) {
            for (const per of permissions) {
                if (per.target === functionName) {
                    return true;
                }
            }
        }
        return false;
    }

    private configNumberDirective = {
        decimal: true,
        decimalLength: 3,
        decimalSeparator: '.',
        groupSeparator: ','
    };

    getConfigNumberDirective() {
        return this.configNumberDirective;
    }

    formatNumberCurrencyExt(valueInput: any): any {
        let configNumberCurrencyParse = {
            decimalSeparator: '.',
            groupSeparator: ','
        };
        if (!valueInput) {
            return 0;
        }
        valueInput = valueInput + '';
        if ((!valueInput && valueInput != 0) || valueInput.trim().length == 0) {
            return '';
        }

        let decimalValue = '';
        let decimalSeparator = '';
        let value = valueInput;

        if (valueInput.indexOf(configNumberCurrencyParse.decimalSeparator) > -1
            && this.getConfigNumberDirective().decimal) {
            let arr = valueInput.split(configNumberCurrencyParse.decimalSeparator);
            value = arr[0];
            if (!value || value.length === 0) {
                value = '0';
            }
            decimalValue = arr[1].substring(0, this.getConfigNumberDirective().decimalLength);
            decimalSeparator = this.getConfigNumberDirective().decimalSeparator;
        }

        if (isNaN(value)) {
            return value + decimalSeparator + decimalValue;
        }
        if (value === 0) {
            return value + decimalSeparator + decimalValue;
        }
        let sign = (value == (value = Math.abs(value)));
        value = Math.floor(value * 100 + 0.50000000001);
        value = Math.floor(value / 100).toString();
        for (let i = 0, length = Math.floor((value.length - (1 + i)) / 3); i < length; i++) {
            value = value.substring(0, value.length - (4 * i + 3)) + this.getConfigNumberDirective().groupSeparator + value.substring(value.length - (4 * i + 3));
        }
        value = value + decimalSeparator + decimalValue;
        return (((sign) ? '' : '-') + value);
    }

    formatDate(value: any, format: string = MOMENT_DATE_FORMAT.ONLY_DATE): string {
        if (value && (typeof value === 'number' || typeof value === 'string')) {
            const d: Date = new Date(value);
            const m: moment.Moment = moment(d);
            return m.format(format);
        } else if (value && value instanceof Date) {
            const m: moment.Moment = moment(value);
            return m.format(format);
        }
        return '';
    }

    formatDateFullTime(value: any, format: string = MOMENT_DATE_FORMAT.FULL_TIME): string {
        if (value && (typeof value === 'number' || typeof value === 'string')) {
            const d: Date = new Date(value);
            const m: moment.Moment = moment(d);
            return m.format(format);
        } else if (value && value instanceof Date) {
            const m: moment.Moment = moment(value);
            return m.format(format);
        }
        return '';
    }

    formatNumberCurrencyExt_VND(valueInput: any): any {
        let configNumberCurrencyParse = {
            decimalSeparator: '.',
            groupSeparator: ','
        };
        if (!valueInput) {
            return 0 + currency_VND;
        }
        valueInput = valueInput + '';
        if ((!valueInput && valueInput != 0) || valueInput.trim().length == 0) {
            return '';
        }

        let decimalValue = '';
        let decimalSeparator = '';
        let value = valueInput;

        if (valueInput.indexOf(configNumberCurrencyParse.decimalSeparator) > -1
            && this.getConfigNumberDirective().decimal) {
            let arr = valueInput.split(configNumberCurrencyParse.decimalSeparator);
            value = arr[0];
            if (!value || value.length === 0) {
                value = '0';
            }
            decimalValue = arr[1].substring(0, this.getConfigNumberDirective().decimalLength);
            decimalSeparator = this.getConfigNumberDirective().decimalSeparator;
        }

        if (isNaN(value)) {
            return value + decimalSeparator + decimalValue + currency_VND;
        }
        if (value === 0) {
            return value + decimalSeparator + decimalValue + currency_VND;
        }
        let sign = (value == (value = Math.abs(value)));
        value = Math.floor(value * 100 + 0.50000000001);
        value = Math.floor(value / 100).toString();
        for (let i = 0, length = Math.floor((value.length - (1 + i)) / 3); i < length; i++) {
            value = value.substring(0, value.length - (4 * i + 3)) + this.getConfigNumberDirective().groupSeparator + value.substring(value.length - (4 * i + 3));
        }
        value = value + decimalSeparator + decimalValue;
        return (((sign) ? '' : '-') + value) + currency_VND;
    }
}

const currency_VND = ' VNĐ';