import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { APP_CONFIG } from 'app.config';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import moment from 'moment';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    actionRequiredNum = 0;
    actionExprising = 0;
    actionComplete = 0;
    actionWaiting = 0;
    actionNotification = 0;
    fullName: string;
    profileImage;
    createEnvelop: boolean = false;
    items: Observable<any[]>;
  
    constructor(
        private httpHelper: HttpHelper,
         private _translocoService: TranslocoService) {
        // firestore.collection('envelopes').doc('envelopes').collection('dang.phan.2@vng.com.vn').valueChanges().subscribe((data)=>{
        //     console.log(data);
        // });
    }

    getCurrentTask(): Promise<any> {
        return null;
    }

    getExprireSoon(): Promise<any> {
        return null;
    }

    getEnvsumBasic(): Promise<any> {
        return this.httpHelper.methodGetService(APP_CONFIG.MCREVL + 'envelope/getEnvsumBasic');
    }

    responseGetMailsByFilter(page, filter, startRow, endRow, request, response): any[] {
        const data = response.data;
        const mails = [];
        if (UtilCommon.checkISArray(data)) {
            for (let obj of data) {
                let istbRecipientDtos = obj.istbRecipientDtos;
                let to = '';
                let ccCount = 0;
                if (istbRecipientDtos && istbRecipientDtos.length > 0) {
                    to = istbRecipientDtos[0].recipientName;
                    ccCount = istbRecipientDtos.length - 1;
                    // for (let reObj of istbRecipientDtos) {
                    //     if (istbRecipientDtos.indexOf(reObj) === (istbRecipientDtos.length - 1)) {
                    //         to += reObj.recipientName;
                    //     } else {
                    //         to += (reObj.recipientName + ', ');
                    //     }
                    // }
                }
                mails.push({
                    "id": obj.id,
                    "code": obj.envelopeCode,
                    "aggId": obj.aggId,
                    "aggIdExt": obj.aggId + (obj.taskId ? ('#' + obj.taskId) : ''),
                    "from": obj.accountCode, // Nguoi tao
                    "to": to,
                    "ccCount": ccCount,
                    "date": obj.checkerDate,         // Ngay cap nhat
                    "subject": obj.emailSubject,
                    "content": obj.emailMessage,
                    "attachments": [],
                    "status": obj.status,
                    "statusLabel": this.genStatusLabel(obj.status),
                    "statusSecondLabel": this.genStatusSecond(obj),
                    "iconStatus": this.genIconStatus(obj.status),
                    "processPercent": obj.completePercent, // this.checkStatus(obj.status),
                    "taskId": obj.taskId,
                    "taskDefKey": obj.taskDefKey,
                    "authFailedInfo": obj.authFailedInfo,
                    "showProgressBar": this.checkShowProgressBar(obj.status)
                })
            }
        }
        return mails;
    }

    genStatusLabel(status): string {
        // return this._translocoService.translate('ENVELOP.STATUS_' + status);
        return 'ENVELOP.STATUS_' + status;
    }

    genStatusSecond(obj): string {
        if (obj.status === 'WAITING_FOR_SIGN') {
            if (obj.authFailedInfo) {
                return 'ENVELOP.STATUS_AUTHETICATION_FAIL';
            } else if (obj.extInfo && obj.extInfo.expirationDetail) {
                let expirationDetail = obj.extInfo.expirationDetail;
                let expirationStatus = expirationDetail.expirationStatus;
                // EXPIRED, EXPIRINGSOON, NORMAL
                if (expirationStatus === 'EXPIRED') {
                    return 'ENVELOP.STATUS_EXPIRED';
                } else if (expirationStatus === 'EXPIRINGSOON') {
                    return 'ENVELOP.STATUS_EXPIRING_SOON';
                }
            } else if (obj.expiration) {
                let momentA = moment(new Date(obj.expiration), "DD/MM/YYYY");
                let momentB = moment(new Date(), "DD/MM/YYYY");
                if (momentA < momentB) {
                    return 'ENVELOP.STATUS_EXPIRED';
                }
            }
        }
        return null;
    }

    genIconStatus(status): any {
        if (status === 'DRAFT') {
            return {
                icon: 'heroicons_outline:pencil-alt',
                color: 'blue'
            };
        } else if (status === 'WAITING_FOR_SIGN') {
            return {
                icon: 'heroicons_outline:clock',
                color: 'orange'
            };
        } else if (status === 'COMPLETED') {
            return {
                icon: 'heroicons_outline:check',
                color: 'green'
            };
        } else if (status === 'DECLINED') {
            return {
                icon: 'heroicons_outline:x',
                color: 'red'
            };
        } else if (status === 'VOIDED') {
            return {
                icon: 'heroicons_outline:ban',
                color: 'red'
            };
        } else if (status === 'DELETED') {
            return {
                icon: 'heroicons_outline:trash',
                color: 'red'
            };
        } else if (status === 'EXPIRED') {
            return {
                icon: 'heroicons_outline:exclamation-circle',
                color: 'orange'
            };
        } else {
            return {
                icon: 'heroicons_outline:check',
                color: 'blue'
            };
        }
    }


    checkShowProgressBar(status): boolean {
        if (status === 'COMPLETED'
            || status === 'DECLINED'
            || status === 'DELETED'
            || status === 'VOIDED') {
            return false;
        }
        return true;
    }

    clearData(): void {
        this.actionRequiredNum = 0;
        this.actionExprising = 0;
        this.actionComplete = 0;
        this.actionWaiting = 0;
        this.actionNotification = 0;
        this.fullName = '';
        this.profileImage = null;
        this.createEnvelop = false;
    }

}
