import { Inject, Injectable } from "@angular/core";
import { CloudWatchLogsClient, PutLogEventsCommand } from "@aws-sdk/client-cloudwatch-logs";
import { from, of } from "rxjs";
import { AWS_CLOUD_WATCH_CLIENT_CONFIG, AWS_STREAM_CONFIG } from "./aws-config.di";
import { AWSCloudWatchClientConfig, AWSCloudWatchStreamConfig, InputLogType } from "./aws-config.model";

@Injectable({ providedIn: 'root' })
export class CloudWatchLoggerService {

    private _client: CloudWatchLogsClient = null;
    constructor(
        @Inject(AWS_STREAM_CONFIG) private readonly _awsCloudWatchStreamConfig: AWSCloudWatchStreamConfig,
        @Inject(AWS_CLOUD_WATCH_CLIENT_CONFIG) private readonly _awsCloudWatchClientConfig: AWSCloudWatchClientConfig
    ) {
        if (this._awsCloudWatchClientConfig) {
            this._initalAwsCloudWatchCLient(this._awsCloudWatchClientConfig);
        }
    }

    private _initalAwsCloudWatchCLient(configure: AWSCloudWatchClientConfig) {
        this._client = new CloudWatchLogsClient({
            region: configure.awsRegion,
            credentials: {
                accessKeyId: configure.awsAccessKey,
                secretAccessKey: configure.awsSecretKey
            }
        })
    }

    public logError(input: InputLogType) {
        if (!input) {
            return of([]);
        }
        return from(this._client.send(this._getLogEvent(input)));
    }

    private _getLogEvent(input: InputLogType): PutLogEventsCommand {
        return new PutLogEventsCommand({
            logGroupName: this._awsCloudWatchStreamConfig.logGroupName,
            logStreamName: this._awsCloudWatchStreamConfig.logStreamName,
            logEvents: [
                {

                    message: JSON.stringify({ data: input }),
                    timestamp: new Date().getTime()
                }
            ]
        });
    }
}