import { Injectable } from '@angular/core';
import { HttpHelper } from './http/HttpHelper';
import { UtilCommon } from './UtilCommon';
import map from 'lodash-es/map';
import { API_URL } from 'app.config';
import snakeCase from 'lodash-es/snakeCase';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {
    MIME_EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    EXCEL_EXT = '.xlsx';

    constructor(private _httpHelper: HttpHelper,
        private _translateService: TranslocoService) {
    }


    transformFieldName(fieldName: string): string {
        const separator = '.';
        return map(fieldName.split(separator), snakeCase).join(separator).toUpperCase();
    }

    private _createColumns(screenName: string, cols: string[]): any[] {
        return cols.map(c => ({
            name: c,
            title: this._translateService.translate(`${screenName}.${this.transformFieldName(c)}`),
        }));
    }

    private _processResponse(response: any, fileName = '', screenName = ''): Promise<any> {
        if (response.blob.type.indexOf('text/xml') >= 0) {
            return UtilCommon.readBlobAsText(response.blob)
                .then(text => {
                    try {
                        const json = JSON.parse(text);
                        if (!json) {
                            return json;
                        } else {
                            return json.data || json;
                        }
                    } catch (e) {
                        return text;
                    }
                });
        } else {
            UtilCommon.downloadFile(
                fileName || response.fileName || (screenName + this.EXCEL_EXT).toLowerCase(),
                response.blob);
            return Promise.resolve(false);
        }
    }

    export({ cols = [], filterModel = {}, screenName = '', sortModel = [] }: any = {},
        fileName: string = ''): Promise<any> {
        const params: any = {
            filterModel,
            sortModel,
            columns: this._createColumns(screenName, cols)
        };
        return this._httpHelper.methodPostBlob(
            API_URL.EXCEL_SERVICE[screenName],
            JSON.stringify(params)
        ).then((response: any) => this._processResponse(response, fileName, screenName));
    }

    downloadExportedFile(jobExecutionId: string, fileName: string = ''): Promise<any> {
        const url = `${API_URL.EXCEL_SERVICE.DOWNLOAD_EXPORTED_FILE}?jobExecutionId=${jobExecutionId}`;
        return this._httpHelper.methodGetFileService(url)
            .then((response: any) => this._processResponse(response, fileName, jobExecutionId));
    }

    exportWithColumns({ columns = [], filterModel = {}, screenName = '', sortModel = [] }: any = {},
        fileName: string = ''): Promise<any> {
        const params: any = {
            filterModel,
            sortModel,
            columns
        };
        return this._httpHelper.methodPostBlob(
            API_URL.EXCEL_SERVICE[screenName],
            JSON.stringify(params)
        ).then((response: any) => this._processResponse(response, fileName, screenName));
    }

    exportChargeCheck(url: string, params: any, fileName: string = ''): Promise<any> {
        return this._httpHelper.methodPostBlob(
            url,
            params
        ).then((response: any) => this._processResponse(response, fileName, ''));
    }

    exportWithColumnsUrl(url: any, { columns = [], filterModel = {}, screenName = '', sortModel = [] }: any = {},
        fileName: string = ''): Promise<any> {
        const params: any = {
            filterModel,
            sortModel,
            columns
        };
        return this._httpHelper.methodPostBlob(url,
            JSON.stringify(params)
        ).then((response: any) => this._processResponse(response, fileName, screenName));
    }

    recoveryExport(url: any, params: any, fileName: string): Promise<any> {
        return this._httpHelper.methodPostBlob(url,
            JSON.stringify(params)
        ).then((response: any) => this._processResponse(response, fileName));
    }
}
