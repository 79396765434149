import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { AppState } from 'app/shared/common/utils/AppState';

@Injectable({
    providedIn: 'root'
})
export class UserServiceHeader {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    public _isTriggerUpdateAvatar: BehaviorSubject<boolean> = new BehaviorSubject(null);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _appState: AppState) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    setUser(value: User): void {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Get the current logged in user data
    //  */
    get(): Observable<any> {
       try{
        if (this._appState.getAppState()) {
            const userInfo = this._appState.getAppState();
            const user: User = {
                id: userInfo?.moreInfo?.contactEmail,
                name: userInfo.fullName,
                email: userInfo?.moreInfo?.contactEmail,
                tenantName: userInfo?.moreInfo?.companyName,
                tenantCode: userInfo?.moreInfo?.tenantCode
            };
            // const sequence = new Observable();
            // sequence.pipe()
            return this._user.pipe(
                map(()=>{
                    this._user.next(user);
                })
            );
            // return this._httpClient.get<User>('api/common/user').pipe(
            //     tap(() => {
            //         this._user.next(user);
            //     })
            // );

        }
       } catch(e){
           console.log(e);
       }
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    triggerUpdateAvatar(isTrigger: boolean) {
        this._isTriggerUpdateAvatar.next(isTrigger);
      }
}
